.carta-postulaciones {
    width: 100% !important;
    height: 100% !important;
    background: transparent linear-gradient(126deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
  
  .carta-postulaciones-dark {
    width: 100% !important;
    height: 100% !important;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
  
  .carta-postulaciones-resultados {
    width: 100% !important;
    height: 70vh !important;
    background: transparent linear-gradient(136deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
    text-align: left;
    overflow-x: hidden;
  }
  .contenido-card{
    margin-top: 2em;
    margin-left: 6em;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .carta-promocionarse {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em;
    width: 300px !important;
    height: 300px !important;
    background: transparent linear-gradient(136deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
    text-align: left;
    gap: 1;
  }
  .imgWork{
    width: 240px;
  }

  .carta-postulaciones-resultados-dark {
    width: 100% !important;
    height: 70vh !important;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
      no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
  
  @media screen and (max-width: 800px) {
    .carta-postulaciones-resultados {
      width: 100% !important;
      height: 100% !important;
      background: transparent linear-gradient(136deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box !important;
      box-shadow: 5px 4px 16px #d0d7e2 !important;
      border-radius: 50px !important;
      opacity: 1 !important;
    }
    .contenido-card{
      margin-top: 2em;
      margin-left: 2em;
      max-width: 100%;
      box-sizing: border-box;
    }
    .contenidoPost{
      margin-top: 0.8em;
      margin-bottom: 1em;
      width: 20em !important;
    }
    .separator{
      width: 5em !important;

    }
  }
  .titulo-cartas {
    margin: 8px 0 0 0 !important; /* Ajusta el margen para subir el título */
    padding-top: 4px !important;  /* Reduce el espacio superior */
    font-size: 18px;
    color: #859F00;
    text-align: center;
    margin-bottom: 20px !important;
  }
  
  .texto-cartas {
    margin: 2px 0 !important;  /* Reduce el margen entre párrafos */
    padding: 0 !important;    /* Elimina el padding extra */
    font-size: 16px;
    text-align: center;
  }
  
  .cartas-posiciones {
    width: 420px !important;
    min-height: 120px !important;
    background: transparent linear-gradient(150deg, #ffffff 0%, #e5e8ee 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
    display: flex;
    flex-direction: column; /* Apila los elementos verticalmente */
    justify-content: center;
    align-items: center;
    padding: 20px !important; /* Ajusta el padding interno */
  }
  
  .cartas-posiciones-dark {
    width: 420px !important;
    height: auto !important;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
  .cartas-cuestionarios {
    width: 420px !important;
    height: auto !important;
    background: transparent linear-gradient(150deg, #ffffff 0%, #e5e8ee 100%) 0%
      0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #d0d7e2 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
  
  .cartas-cuestionarios-dark {
    width: 420px !important;
    height: auto !important;
    background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px !important;
    opacity: 1 !important;
  }
  .contenedorbutton .bg-data-puestos {
    width: 25% !important;
    height: 55px;
    background:transparent linear-gradient(100deg, #B3C300 20%, #A8BA00 32%, #8EA600 68%, #859F00 89%) 0% 0% no-repeat padding-box !important;
    box-shadow: 12px 12px 18px #1D5DAA3B;
    border-radius: 50px;
    opacity: 1;
    color: white !important;
    
  }
  
  .bg-data-puestos-dark {
    width: 100%;
    height: 55px;
    background: transparent linear-gradient(100deg, #B3C300 20%, #A8BA00 32%, #8EA600 68%, #859F00 89%) 0% 0% no-repeat padding-box !important;
    box-shadow: 5px 4px 16px #0c0c0c !important;
    border-radius: 50px;
    opacity: 1;
    color: #fbfbfb !important;
  }
  
  @media only screen and (max-width: 979px) {
    .bg-data-puestos {
      width: 100%;
      height: 60px;
      background: transparent linear-gradient(135deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 50px;
      opacity: 1;
      color: white !important;
    }
  
    .bg-data-puestos-dark {
      width: 100%;
      height: 60px;
      background: transparent linear-gradient(97deg, #292b2f 0%, #16181a 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #0c0c0c !important;
      border-radius: 50px;
      opacity: 1;
      color: #fbfbfb !important;
    }
  }
  
  @media only screen and (max-width: 820px) {
    .bg-data-puestos {
      width: 100%;
      height: 60px;
      background: transparent linear-gradient(135deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 50px;
      opacity: 1;
      color: white !important;
    }
    .contenido-card{
      margin-top: 2em;
      margin-left: 2em;
      max-width: 100%;
      box-sizing: border-box;
    }
    .contenido-card .separator{
      width: 18em !important;
    }
  }
  @media only screen and (max-width: 440px) {
    .contenedorbutton .bg-data-puestos {
      width: 300px !important;
      height: 60px;
      background: transparent linear-gradient(135deg, #ffffff 0%, #e5e8ee 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 5px 4px 16px #d0d7e2;
      border-radius: 50px;
      opacity: 1;
      color: white !important;
    }
  }
  .header-puestos{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
  }
  .input-container {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1em;
    }
    
    .buscarPuestos {
      width: 100%;
      background: transparent linear-gradient(131deg, #FFFFFF 9%, #EEF1F4 87%) 0% 0% no-repeat padding-box;
      box-shadow: 12px 12px 18px #1D5DAA3B;
      border-radius: 32px;
      opacity: 1;
      height: 50px;
      padding: 10px 10px 10px 45px; /* Espacio a la izquierda para la lupa */
      font-size: 16px;
      border: 1px solid #ccc;
      outline: none;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    
    .search-icon {
      position: absolute;
      left: 15px; /* Ubica la lupa dentro del input */
      top: 50%;
      transform: translateY(-50%); /* Centra verticalmente */
      color: #777;
      font-size: 18px;
      pointer-events: none; /* Evita que la lupa sea clickeable */
    }
    .iconoPostulante {
      font-size: 18px;  /* Reduce el tamaño del icono si es un ícono basado en fuente */
      width: 24px;      /* Ajusta el ancho del icono */
      height: 24px;     /* Ajusta la altura del icono */
      margin-right: 5px; /* Espacio entre el icono y el texto */
  }
  .iconoLocacion {
    font-size: 26px;  /* Reduce el tamaño del icono si es un ícono basado en fuente */
    width: 28px;      /* Ajusta el ancho del icono */
    height: 28px;     /* Ajusta la altura del icono */
    margin-right: 5px; /* Espacio entre el icono y el texto */
}
.separator {
  width: 33em !important;
  height: 1px;
  background: #5F83B7;
  margin: 10px 0;
}
@media only screen and (max-width: 1380px){
  .separator {
    width: 22em !important;
    height: 1px;
    background: #5F83B7;
    margin: 10px 0;
  }
}
.contenidoPost{
  margin-top: 0.8em;
  margin-bottom: 1em;
  width: 33em;
}
.contenedor-puestos {
  margin-top: 1.5em;
  height: 50vh; /* Altura fija */
  overflow-y: auto; /* Scroll interno */
  padding-right: 10px; /* Espacio para que el scroll no tape contenido */
}
.contenedor-postulacion {
  margin-top: 1.5em;
}
.trabajaConNosotros{
  display: flex;
  justify-content: center;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-card-mobile {
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 50px;
  position: relative;
}
.contenido-modal{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2em;
}
.separatorMobile {
  width: 100% !important;
  height: 2px;
  background: #5F83B7;
  margin: 10px 0;
}

.cerrar-modal {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  z-index: 10;
}

@media (min-width: 820px) {
  .modal-overlay {
    display: none !important;
  }
}
