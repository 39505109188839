/* Custom Alert Popup */
.custom-alert-popup {
    border-radius: 24px !important;
    padding: 2rem !important;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Lato', sans-serif;
    text-align: center;
}

.custom-confirm-button {
    background-color: #B4C400 !important;
    color: white !important;
    border-radius: 32px !important;
    padding: 10px 24px;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    margin-right: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.custom-cancel-button {
    background-color: #3C435E !important;
    color: white !important;
    border-radius: 32px !important;
    padding: 10px 24px;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.swal2-icon {
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
}

/* Header Container */
.header-container-zoco {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    padding-bottom: 20px; /* Adjust the space below the logo and bar */
}

/* Logo */
.logo-zoco {
    width: 80px;
    height: auto;
    display: block;
    margin-right: 20px; /* Space between logo and vertical bar */
}

/* Vertical Bar */
.bar-vertical-zoco {
    height: 50px; /* Adjust height of the vertical bar */
    width: 3px; /* Width of the vertical bar */
    background-color: #424960; /* Color of the vertical bar */
    margin-right: 20px; /* Space between vertical bar and title */
}

/* Title (h2) */
h2.zoco-title {
    font-size: 1.5rem; /* Adjust text size */
    letter-spacing: 0.16px;
    color: #424960;
    opacity: 1;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .header-container-zoco {
        flex-direction: row; /* Maintain horizontal layout for logo, bar and title */
        align-items: center; /* Center items horizontally on small screens */
    }

    .logo-zoco {
        margin-right: 10px; /* Reduced space between logo and vertical bar */
        margin-bottom: 0; /* Remove bottom margin */
    }

    .bar-vertical-zoco {
        margin-right: 10px; /* Adjust the space between the bar and title */
        margin-bottom: 0; /* Remove bottom margin */
        width: 50px; /* Adjust width of the vertical bar for mobile */
        height: 2px; /* Make the bar thinner on mobile */
    }

    h2.zoco-title {
        font-size: 1.2rem; /* Adjust text size for mobile */
    }
}

/* Estilo para la carta (formulario) en pantallas grandes (escritorio) */
.card-form {
    position: relative;
    top: 158px; /* Mantenemos la posición relativa */
    left: 301px; /* Mantenemos la posición relativa */
    width: 1318px;
    height: 839px;
    background: transparent linear-gradient(131deg, #FFFFFF 26%, #EEF1F4 71%) 0% 0% no-repeat padding-box;
    box-shadow: 12px 12px 18px #1D5DAA3B;
    border-radius: 49px;
    opacity: 1;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px; /* Espacio entre el logo y la carta */
}

/* Responsividad para pantallas más pequeñas */
@media (max-width: 1024px) {
    .card-form {
        top: 80px; /* Ajusta la posición para pantallas más pequeñas */
        left: 20px; /* Reduce la distancia a los bordes */
        width: calc(100% - 40px); /* Hace que ocupe el 100% del ancho disponible */
        height: auto; /* Permite que la altura se ajuste automáticamente */
        border-radius: 24px; /* Más redondeo en pantallas pequeñas */
        padding: 15px; /* Menos padding en pantallas pequeñas */
    }
}

@media (max-width: 600px) {
    .card-form {
        top: 60px; /* Ajuste para pantallas móviles */
        width: calc(100% - 40px); /* Ocupa todo el ancho disponible */
        height: auto; /* Ajusta la altura automáticamente */
        border-radius: 24px;
        padding: 15px;
    }
}
