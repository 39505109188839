.content-img-landing-2
    {
        position: relative;
        width: 100%;
        padding-bottom:28.25%;
      }
      .content-img-landing-2 img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; 
        height:100%;
      
      }
      .navbar-item-landing2 a {
        text-decoration: none !important;
        color: black !important;
        font-weight: 700 !important;
        font-size: 1.1rem;
        transition: all 0.3s !important;
        padding-top: 0.2em !important;
        padding-bottom: 0.2em !important;
      }
      .navbar-item-landing2 a:hover {
        color: var(--verde-zoco) !important;
        background-color: transparent !important;
      }
      .page-landign {
        margin-top: 3vh;
    }

    .img-mobile {
      display: none;
    }
    
    .img-desktop {
      display: block;
    }
    @media (max-width: 1024px){
      
    }
    
    @media (max-width: 820px) {
      .img-mobile {
        display: block;
        
      }
    
      .img-desktop {
        display: none;
      }
      .content-img-landing-2
    {
        position: relative;
        width: 100%;
        padding-bottom:86.25%;
      }
    }